@mixin font-face(
  $fontname,
  $filename: $fontname,
  $weight: normal,
  $style: normal
) {
  @font-face {
    font-family: $fontname;
    font-style: $style;
    font-weight: $weight;
    src: url("../fonts/#{$filename}.woff2") format("woff2"), url("../fonts/#{$filename}.woff") format("woff");
  }
}

@include font-face("Lato", "lato-v17-latin-ext_latin-regular", normal, normal);
@include font-face("Lato", "lato-v17-latin-ext_latin-italic", normal, italic);

@include font-face("Lato", "lato-v17-latin-ext_latin-700", 700, normal);
@include font-face("Lato", "lato-v17-latin-ext_latin-700italic", 700, italic);
