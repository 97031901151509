@mixin vertical-rule {
  margin: $hr-margin-y;
  border: 0;
  // border-left: $hr-border-width solid $hr-border-color;
}
@mixin horizontal-rule {
  margin: $hr-margin-y 0;
  border: 0;
  // border-top: $hr-border-width solid $hr-border-color;
}

.tiaki-page-grid {
  @include make-container();
  @include media-breakpoint-up("xl") {
    max-width: map-get($container-max-widths, "xl");
  }
  @include media-breakpoint-down("xs") {
    flex-grow: initial;
    grid:
      [row1-start] "sidebar" auto [row1-end]
      [row2-start] "sidebarRule" auto [row2-end]
      [row3-start] "content" auto [row3-end]
      [row4-start] "footerRule" auto [row4-end]
      [row5-start] "footer" auto [row5-end]
      / 1fr;
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr auto;
  }

  display: grid;
  flex-grow: 1;
  padding-top: $grid-gutter-width / 2;
  padding-bottom: $grid-gutter-width / 2;
  grid:
    [row1-start] "sidebar content" 1fr [row1-end]
    [row2-start] "footerRule footerRule" auto [row2-end]
    [row3-start] "footer footer" auto [row3-end]
    / auto 1fr;

  // stylelint-disable-next-line selector-max-id
  #sidebar {
    margin-right: $nav-link-padding-x;
    grid-area: sidebar;
  }

  main {
    flex-grow: 1;
    grid-area: content;
  }

  // stylelint-disable-next-line selector-max-id
  #footer-rule {
    @include horizontal-rule();
    grid-area: footerRule;
  }

  footer {
    grid-area: footer;
  }
}
