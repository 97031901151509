
@import "custom-components/ripple";

.page-dialog {
  width: 100%;
  padding: 2.5rem;
  margin: 0 auto;
  background-color: $white;

  @media (min-width: 35rem) {
    width: 35rem;
  }

  img {
    max-width: 100%;
  }
}

.catalyst-logo-dialog {
  max-height: 1.5rem;
  margin: 1rem;
}

.qr-code {
  margin-bottom: 20px;
}

// stylelint-disable-next-line selector-max-id
#u2f-message-block {
  margin-top: 15px;
  font-weight: $font-weight-bold;
  text-align: center;
}

.dialog-centered {
  margin-top: 25px;
  text-align: center;
}

body {
  @include media-breakpoint-up("sm") {
    min-height: 100vh;
  }

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  background-color: $light;
}
