@mixin form-validation-state-selector($state) {
  // @content;
  @if ($state == "invalid") {
    #{if(&, "&", "")}[aria-invalid="true"],
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
  @if ($state == "valid") {
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}
