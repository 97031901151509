// Custom variables
//
//

// Catalyst brand-colors
$catalyst-red: #ba2025;
$catalyst-black: #190f10;
$catalyst-brown: #50433c;
$catalyst-mustard: #c6b253;
$catalyst-grey: #c3c3c3;
$catalyst-lightgrey: #f0f0f0;
$catalyst-darkgreen: #33402e;
$catalyst-lightgreen: #889a3a;
$catalyst-yellow: #e6ad30;
$catalyst-orange: #dd8545;
$catalyst-blue: #5b9aa9;
$catalyst-blue-darker: #4b7c8a;

$catalyst-cloud-blue: #1d414e;
$catalyst-cloud-grey: #454545;
$catalyst-cloud-lime: #a9d73d;
$catalyst-totara-green: #687d35;
$catalyst-mahara-olive: #474120;
$catalyst-drupal-blue: #0163a4;
$catalyst-moodle-orange: #ac6527;
$catalyst-koha-green: #53833b;
$catalyst-openstack-red: #ce3327;
$catalyst-linux-grey: #404041;

$catalyst-colors: (
  "red": $catalyst-red,
  "black": $catalyst-black,
  "brown": $catalyst-brown,
  "mustard": $catalyst-mustard,
  "grey": $catalyst-grey,
  "lightgrey": $catalyst-lightgrey,
  "darkgreen": $catalyst-darkgreen,
  "lightgreen": $catalyst-lightgreen,
  "yellow": $catalyst-yellow,
  "orange": $catalyst-orange,
  "blue": $catalyst-blue,
  "cloud-blue": $catalyst-cloud-blue,
  "cloud-grey": $catalyst-cloud-grey,
  "cloud-lime": $catalyst-cloud-lime,
  "totara-green": $catalyst-totara-green,
  "mahara-olive": $catalyst-mahara-olive,
  "drupal-blue": $catalyst-drupal-blue,
  "moodle-orange": $catalyst-moodle-orange,
  "koha-green": $catalyst-koha-green,
  "openstack-red": $catalyst-openstack-red,
  "linux-grey": $catalyst-linux-grey,
);
