.mci-icon::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -.125em;
  content: "";
  background-repeat: no-repeat;
  background-size: 1em 1em;
}

.mci-icon.mci-icon-2x::before {
  width: 2em;
  height: 2em;
  background-size: 2em 2em;
}


.mci-phone::before {

  background-image: url("../img/phone.svg");

}

.mci-security-key::before {
  background-image: url("../img/securitykey.svg");
}
