@import "base/custom-variables";

// Override Bootstrap variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #fafafa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$primary:   $catalyst-blue-darker;
$secondary: $catalyst-grey;
$success:   $catalyst-lightgreen;
$info:      $catalyst-blue;
$warning:   $catalyst-orange;
$danger:    $catalyst-red;
$light:     $gray-100 !default;
$dark:      $catalyst-black;


// Bootstrap configuration


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true;


// Body
//
// Settings for the `<body>` element.

$body-bg: $light;


// Links
//
// Style anchor elements.

$link-color:            $primary;
$link-decoration:       underline;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Lato", sans-serif;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;


// List group

$list-group-bg: $light;
