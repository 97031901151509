.page-help {
  padding: .5em;
  margin: .5em;
  background-color: #fdb;
  border: 3px dotted #ea3;
}
.page-help p {
  width: 100%;
  margin: 0;
}
.page-help-title {
  cursor: pointer;
}
.page-help [data-bs-toggle="collapse"]::before {
  content: "− ";
}
.page-help [data-bs-toggle="collapse"].collapsed::before {
  content: "+ ";
}
