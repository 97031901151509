.totp-alternative-link {
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
}

.second-factor-icon {
  width: 30px;
  height: 30px;
}
