@import "../variables";

@function block-grad($colors, $grad-stops) {
  $stops: zip($colors, $grad-stops);
  $grad: ();
  $last-pos: 0%;
  @each $color, $position in $stops {
    $grad: append($grad, ($color $last-pos), "comma");
    $grad: append($grad, ($color $position), "comma");
    $last-pos: $position;
  }
  @return $grad;
}
@function random-percentage-steps($stops, $min-per-step: 0%) {
  $steps: ();
  $last-step: $min-per-step;
  @for $i from 1 through ($stops - 1) {
    $max: 100% - ($min-per-step * ($stops - $i));
    $val: $last-step;
    @if ($max - $last-step) > 0 {
      $val: $last-step + random($max - $last-step);
    }
    $steps: append($steps, $val);
    $last-step: $val + $min-per-step;
  }
  $steps: append($steps, 100%);
  @return $steps;
}


.stripe-top {
  $colors: (
    map-get($catalyst-colors, "red"),
    map-get($catalyst-colors, "orange"),
    map-get($catalyst-colors, "yellow"),
    map-get($catalyst-colors, "blue"),
    map-get($catalyst-colors, "lightgreen"),
  );
  border-top: map-get($spacers, 2) solid;
  border-image-source: linear-gradient(to right, block-grad($colors, random-percentage-steps(length($colors), 10%)));

  border-image-slice: 10;
}
