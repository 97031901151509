.application-logo {
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-right: .2em;
  vertical-align: middle;

  &.logo-placeholder {
    background-image: linear-gradient(to bottom right, #ba2025 0%, #ba2025 23%, #dd8545 23%, #dd8545 41%, #e6ad30 41%, #e6ad30 59%, #5b9aa9 59%, #5b9aa9 77%, #889a3a 77%, #889a3a 100%);
  }
}
