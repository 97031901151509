@import "../variables";

@mixin ripple($name, $time, $colors, $width, $padding: $width / 10, $animation-percentage: 100 * (2 / length($colors))) {

  @at-root {
    @keyframes #{$name}-animation {
      0% {
        top: ($width / 2);
        left: ($width / 2);
        width: 0;
        height: 0;
        opacity: 1;
      }

      #{$animation-percentage}% {
        top: $padding;
        left: $padding;
        width: $width - ($padding * 2);
        height: $width - ($padding * 2);
        opacity: 0;
      }

      100% {
        top: ($width / 2);
        left: ($width / 2);
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  position: relative;
  width: $width;
  height: $width;
  margin-right: auto;
  margin-left: auto;

  > div {
    position: absolute;
    top: ($width / 2);
    left: ($width / 2);
    box-sizing: content-box;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
    opacity: 0;
    animation: #{$name}-animation #{$time} cubic-bezier(0, .2, .8, 1) infinite;

    // Arrays are one indexed
    @for $i from 1 through length($colors) {
      &:nth-child(#{$i}) {
        border-color: nth($colors, $i);
        animation-delay: #{-$i * ($time / length($colors))};
      }
    }
  }
}

.loading-ripple {
  margin-top: 15px;

  &.lr-catalyst-colors {
    $colors: (
      map-get($catalyst-colors, "red"),
      map-get($catalyst-colors, "orange"),
      map-get($catalyst-colors, "yellow"),
      map-get($catalyst-colors, "blue"),
      map-get($catalyst-colors, "lightgreen"),
    );
    @include ripple(catalyst-ripple, 2.5s, $colors, 143px);
  }
}
