
.form-control-numeric {
  // stylelint-disable property-no-vendor-prefix
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  // stylelint-enable
}


.form-button-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-between;

  .form-cancel {
    order: -1;
    margin-right: auto;
  }
  .form-submit {
    order: 10;
    margin-left: auto;
  }
}
