.change-password-footer {
  margin-top: 2rem;
}
.change-password-step {
  padding: 1rem;
}
.change-password-step .col {
  padding: 1rem;
}
.change-password-step .col:nth-child(odd) {
  background-color: $gray-400;
}
.change-password-step .col:nth-child(even) {
  background-color: $gray-300;
}
.change-password-step .btn-lg {
  padding: 1.5rem 0;
}
.chpasswd-password-style-heading {
  font-weight: $font-weight-bold;
}

.chpasswd-step-heading {
  color: $gray-500;
}
.chpasswd-step-heading::before {
  content: "\25B8\0020";
}
.chpasswd-step-heading.chpasswd-collapsed {
  color: $black;
  cursor: pointer;
}
.chpasswd-step-heading.chpasswd-current {
  color: $black;
}
.chpasswd-step-heading.chpasswd-current::before {
  content: "\25BE\0020";
}

.chpasswd-example {
  padding: 1rem;
  margin: 1rem 0;
  font-family: monospace;
  color: $catalyst-red;
  background-color: $gray-200;
}

.errorlist {
  font-weight: $font-weight-bold;
  color: #f00;
}

.password-style {
  display: none;
  font-size: 1.1rem;
}
.password-style.active {
  display: block;
}
.password-style ul {
  padding-left: 0;
  list-style: none;
}
.password-style ul li {
  padding-top: .5rem;
}
.password-feedback {
  color: #080;
}
.password-feedback.exception-rule {
  display: none;
}
.password-feedback.exception-rule.not-met {
  display: block;
}
.password-feedback::before {
  content: "\2611\0020";
}
.password-feedback.not-met {
  color: #f00;
}
.password-feedback.not-met::before {
  content: "\2610\0020";
}

@media (min-width: 50rem) {
  .chpasswd-big-dialog {
    width: 50rem !important; /* stylelint-disable-line declaration-no-important */
  }
}
